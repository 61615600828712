import { Grid, Link, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import SubTitle from "../../shared/SubTitle/SubTitle";
import Title from "../../shared/Title/Title";
import './OurPlans.css';
import icon from '../../../assets/icons/plan_icon.svg';
import { useHistory } from "react-router-dom";

const OurPlans = () => {

    const plans = [
        {
            title: 'Basic Use',
            subTitle: 'All the basics for starting a new business',
            price: '$ 5,99',
            description: [
                'Simple and free registration',
                'Free top up',
                'Cashback for online purchases'
            ],
            button: 'Choose Plan'
        },
        {
            title: 'Easy Money',
            subTitle: 'All the basics for starting a new business',
            price: '$ 8,99',
            description: [
                'Up to 20% of cashback',
                'Payment for shopping services without a fee',
                'High credit limit'
            ],
            button: 'Choose Plan'
        },
        {
            title: 'Eco Card',
            subTitle: 'All the basics for starting a new business',
            price: '$ 12,99',
            description: [
                '5% of the cashback - to save the ocean',
                'Individual card design',
                '60 days of grace period'
            ],
            button: 'Choose Plan'
        }
    ]


    const history = useHistory();

    return (
        <Grid container id='ourPlans'>
            <Grid item xs={12}>
                <Title content="Our Plans" align="left" />
            </Grid>
            <Grid item xs={6}>
                <SubTitle content="Choose the best benefits for yourself" />
            </Grid>
            <Grid item xs={6} textAlign="right">
                <Link onClick={() => history.push('/plans')} className="compare">Compare plans →</Link>
            </Grid>
            <Grid container className='plans' spacing={4}>
                {plans.map((plan) => (
                    <PlanCard key={plan.title} title={plan.title} subTitle={plan.subTitle} price={plan.price} description={plan.description} buttonTitle={plan.button} />
                ))}
            </Grid>
        </Grid>
    );
}

interface Props {
    title: string,
    subTitle: string,
    price: string,
    description: Array<string>,
    buttonTitle: string
}

const PlanCard = ({ title, subTitle, price, description, buttonTitle }: Props) => {
    const history = useHistory();

    return (
        <Grid item sm={4} xs={12}>
            <Grid container className="planCard">
                <Grid item xs={12}>
                    <Typography className="title">{title}</Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography className="description">{subTitle}</Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography display="inline" className="price">{price}</Typography><Typography display="inline" className="month">/mo</Typography>
                </Grid>
                    <Grid item xs={12} className="buttonGrid">
                        <PrimaryButton onClick={() => history.push('/plans')} className='button'><span className="text">{buttonTitle}</span></PrimaryButton>
                    </Grid>
                <Grid item xs={12} className="spacer">
                </Grid>
                <Grid item xs={12} className="listGrid">
                    What’s included:
                    <List className='planList'>
                        {description.map((el, index) => (
                            <ListItem className='planList' key={index}>
                                <ListItemIcon className='planListIcon'><img src={icon} alt="Xward" /></ListItemIcon>
                                {el}
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default OurPlans;