import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Faq } from "../../../services/slices/faq/faq.types";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface Props {
    faq: Faq,
    onClick: () => void
}

const FAQCard = ({ faq, onClick }: Props) => {
    return (
        <Card onClick={onClick} id='faqItem' >
            <CardContent sx={{padding: '0', paddingBottom: '0 !important'}}>
                <Grid container className="content" alignItems='center'>
                    <Grid item >
                        <div className='icon'><HelpOutlineIcon sx={{color: '#FFFFFF', width: '3rem', height: 'inherit', display: "flex", margin: 'auto'}}/></div>
                    </Grid>
                    <Grid item >
                        <Typography className="faqName">{faq.name}</Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: 'auto', marginRight: '1rem' }} >
                        <ArrowForwardIosIcon className="chervonIcon" />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default FAQCard;