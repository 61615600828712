import { Box, Grid, Hidden, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PrimaryButton from "../shared/Buttons/PrimaryButton";
import './Plans.css';
import data from "./Plans.json";
import { useHistory } from "react-router-dom";
import included from "../../assets/images/plansIncluded.svg";
import excluded from "../../assets/images/plansExcluded.svg";
import icon from '../../assets/icons/plan_icon.svg';
import open from '../../assets/icons/more_open.svg';
import close from '../../assets/icons/more_close.svg';

const Plans = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [selectedMore, setSelectedMore] = useState<string>('');


    return (
        <>
            <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
                <Grid container id="Plans" spacing={2}>
                    <Grid item sm={3} className="headerRowTop">
                        <Typography className="title">{data.title}</Typography>
                    </Grid>
                    <Grid item sm={3} className="headerRowTop">
                        <Header title={data.basic.title} subTitle={data.basic.subTitle} price={data.basic.price} />
                    </Grid>
                    <Grid item sm={3} className="headerRowTop">
                        <Header title={data.easy.title} subTitle={data.easy.subTitle} price={data.easy.price} />
                    </Grid>
                    <Grid item sm={3} className="headerRowTop">
                        <Header title={data.eco.title} subTitle={data.eco.subTitle} price={data.eco.price} />
                    </Grid>
                    <Grid item sm={12} className="headerNeeds">
                        <Typography className="headerNeedsTitle">Mobile banking needs</Typography>
                    </Grid>
                    {data.plans.map((item, index) => (
                        <>
                            <Grid item sm={3} className="listLeft">
                                <TextItem title={item.title} text={item.text} />
                            </Grid>
                            <Grid item sm={3} className="listCenter">
                                <Item type={data.basic.items[index].type} text={data.basic.items[index].value} />
                            </Grid>
                            <Grid item sm={3} className="listCenter">
                                <Item type={data.easy.items[index].type} text={data.easy.items[index].value} />
                            </Grid>
                            <Grid item sm={3} className="listRight">
                                <Item type={data.eco.items[index].type} text={data.eco.items[index].value} />
                            </Grid>
                        </>
                    ))}
                    <Grid item sm={3} className="headerRowBottom">
                    </Grid>
                    <Grid item sm={3} className="headerRowBottom">
                        <Header title={data.basic.title} subTitle={data.basic.subTitle} price={data.basic.price} />
                    </Grid>
                    <Grid item sm={3} className="headerRowBottom">
                        <Header title={data.easy.title} subTitle={data.easy.subTitle} price={data.easy.price} />
                    </Grid>
                    <Grid item sm={3} className="headerRowBottom">
                        <Header title={data.eco.title} subTitle={data.eco.subTitle} price={data.eco.price} />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}>
                <Grid container id="Plans">
                    <Grid item xs={12}>
                        <Typography className="title">{data.title}</Typography>
                    </Grid>
                    <Grid item xs={12} pt={2}>
                        <HeaderMobile title={data.basic.title} plans={data.basic.items} description={data.basic.description} subTitle={data.basic.subTitle} price={data.basic.price} selectedMore={selectedMore} setSelectectedMore={setSelectedMore} />
                    </Grid>
                    <Grid item xs={12} pt={2}>
                        <HeaderMobile title={data.easy.title} plans={data.easy.items} description={data.easy.description} subTitle={data.easy.subTitle} price={data.easy.price} selectedMore={selectedMore} setSelectectedMore={setSelectedMore} />
                    </Grid>
                    <Grid item xs={12} pt={2}>
                        <HeaderMobile title={data.eco.title} plans={data.eco.items} description={data.eco.description} subTitle={data.eco.subTitle} price={data.eco.price} selectedMore={selectedMore} setSelectectedMore={setSelectedMore} />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

interface Header { title: string, subTitle: string, price: string }
const Header = ({ title, subTitle, price }: Header) => {
    const history = useHistory();
    return (
        <Grid container className="planCard">
            <Grid item xs={12}>
                <Typography className="gridTitle">{title}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className="description">{subTitle}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography display="inline" className="price">{price}</Typography><Typography display="inline" className="month">/mo</Typography>
            </Grid>
            <Grid item xs={12} className="buttonGrid">
                <PrimaryButton onClick={() => history.push('/plans')} className='button'><span className="text">Choose plan</span></PrimaryButton>
            </Grid>
        </Grid>
    );
}

interface HeaderMobile {
    title: string,
    subTitle: string,
    price: string,
    description: Array<string>,
    plans: Array<any>,
    selectedMore: string,
    setSelectectedMore: (selected: string) => any
}
const HeaderMobile = ({ title, subTitle, price, description, plans, selectedMore, setSelectectedMore }: HeaderMobile) => {
    const history = useHistory();
    return (
        <Grid container className="planCard">
            <Grid item xs={12}>
                <Typography className="gridTitle">{title}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className="description">{subTitle}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography display="inline" className="price">{price}</Typography><Typography display="inline" className="month">/mo</Typography>
            </Grid>
            <Grid item xs={12} className="buttonGrid">
                <PrimaryButton onClick={() => history.push('/plans')} className='button'><span className="text">Choose plan</span></PrimaryButton>
            </Grid>
            <Grid item xs={12} className="spacer">
            </Grid>
            <Grid item xs={12} className="listGrid">
                What’s included:
                <List>
                    {description.map((el, index) => (
                        <ListItem key={index}>
                            <ListItemIcon className='planListIcon'><img src={icon} alt="Xward" /></ListItemIcon>
                            {el}
                        </ListItem>
                    ))}
                </List>
            </Grid>
            <Grid item xs={12} className="spacer">
            </Grid>
            <Grid item xs={12} className="listGrid">
                <Grid container>
                    <Grid item xs={9}>
                        More features
                    </Grid>
                    <Grid item xs={3} textAlign="right" onClick={() => setSelectectedMore(title)}>
                        <img src={title === selectedMore ? close : open} />
                    </Grid>
                </Grid>
                <Box display={title === selectedMore ? 'block' : 'none'}>
                    <List>
                        {plans.map((item, index) => (
                            item?.value !== "x" ? (
                                <ListItem key={index}>
                                    <ListItemIcon className='planListIcon'><img src={icon} alt="Xward" /></ListItemIcon>
                                    {item?.type === "image" && item?.value === "c" ? (
                                        data.plans[index].text
                                    ) : (
                                        item?.value
                                    )}
                                </ListItem>) : (null)
                        ))}
                    </List>
                </Box>
            </Grid>
        </Grid>
    );
}

interface Item { type: string, text: string }
const Item = ({ type, text }: Item) => {
    return (
        <Box component="div" m={1} className="dataRow">
            {type === "image" ? (
                <img src={text === 'x' ? excluded : included} alt="" className="rowDataImage" />
            ) : (
                <Typography className="rowDataText">{text}</Typography>
            )}
        </Box>
    );
}

interface TextItem { title: string, text: string }
const TextItem = ({ title, text }: TextItem) => {
    return (
        <Box component="div" m={1} className="dataRowStart">
            <Typography className="rowTitle">{title}</Typography>
            <Typography className="rowText">{text}</Typography>
        </Box>
    );
}

export default Plans;
