import { Grid, Hidden, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import aboutCards from "../../assets/images/about_cards.png";
import futureImage from "../../assets/images/future_image.png";
import PrimaryButton from '../shared/Buttons/PrimaryButton';
import './About.css';

const About = () => {
    const history = useHistory();
    return (
        <Grid container id="About">
            <Grid item md={12} xs={12} sm={12} className="header">
                <Typography className="headerText">About us</Typography>
                <Typography className="headerSubText">Xward pay is a modern bank which provide the customers with a progressive and convinient tool of personal banking</Typography>
            </Grid>
            <Grid md={12} xs={12} sm={12} className="future">
                <Hidden mdDown={true}>
                    <Grid container>
                        <Grid item md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <div className='upSection'>
                                <Typography className="title">Pioneering embedded finance. Powering amazing experiences.</Typography>
                                <Typography className="text">Working in fintech since the 2016s, our founders gained valuable experience of building successful companies and identifying new opportunities.
                                    In 2019 they created XwardPay, assembled a talented team and began building our world-class platform. Together we’re committed to realising our ambition of embedding financial services for brands who want to build deeper engagement with their customers.</Typography>
                            </div>
                            <div className='downSection'>
                                <Typography className="title">Future of finance</Typography>
                                <Typography className="text">Here to break free from the status quo and challenge what banking is and can do, together we built a fully mobile bank & gave you the power over your money. At the end of the day, it's about making life easy so you can save time, save money and save the ocean.</Typography>
                            </div>
                        </Grid>
                        <Grid item md={6}>
                            <img src={futureImage} alt="About Us" />
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden mdUp={true}>
                    <Grid container textAlign='center' id='futureMobile'>
                        <Grid item xs={12} sm={12} className='top'>

                        </Grid>
                        <Grid item xs={1} sm={1}>
                        </Grid>
                        <Grid item xs={10} sm={10} className="text">
                            <div className='upSection'>
                                <Typography className="title">Pioneering embedded finance. Powering amazing experiences.</Typography>
                                <Typography className="text">Working in fintech since the 2016s, our founders gained valuable experience of building successful companies and identifying new opportunities.
                                    In 2019 they created XwardPay, assembled a talented team and began building our world-class platform. Together we’re committed to realising our ambition of embedding financial services for brands who want to build deeper engagement with their customers.</Typography>
                            </div>
                            <div className='downSection'>
                                <Typography className="title">Future of finance</Typography>
                                <Typography className="text">Here to break free from the status quo and challenge what banking is and can do, together we built a fully mobile bank & gave you the power over your money. At the end of the day, it's about making life easy so you can save time, save money and save the ocean.</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={1} sm={1}>
                        </Grid>
                    </Grid>
                </Hidden>

            </Grid >
            <Grid md={12} xs={12} sm={12} className="aboutCards">
                <Grid container>
                    <Hidden mdDown={true}>
                        <Grid item md={6} className="padding">
                            <img src={aboutCards} alt="About Us" />
                        </Grid>
                    </Hidden>
                    <Grid item md={6} sm={12} xs={12} className="paddingCards">
                        <Typography className="textTitleLight">What do you stand for</Typography>
                        <Typography className="textLight">Transparency, freedom and continuous improvement. The belief of continuously improving things defines who you are. With Ownership, Effective Communication and User-Centred Thinking as your core values, you're driven to push boundaries and innovate.</Typography>
                    </Grid>
                    <Hidden mdUp={true}>
                        <Grid item md={6} sm={12} xs={12} className="padding">
                            <img src={aboutCards} alt="About Us" className='cardsImage' />
                        </Grid>
                    </Hidden>
                </Grid>
            </Grid>

            <Grid md={12} xs={12} sm={12} className="who">
                <Grid container textAlign='center' id='whoAre'>
                    <Grid item xs={1} sm={1}>
                    </Grid>
                    <Grid item xs={10} sm={10} className="section">
                        <Typography className="title">Who are The Free</Typography>
                        <Typography className="text">As part of The Free, you are a free independent thinker, that wants the freedom to live life on your own terms. Whether you're an employee or a user, it doesn't matter. You're as ambitious as you are mindful and find sustainability extremely important; wanting to leave your positive mark on the world.</Typography>
                        <PrimaryButton onClick={() => history.push('/coming-soon')} className="button">Coming soon</PrimaryButton>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                    </Grid>
                    <Grid item xs={12} sm={12} className='bottom'>
                    </Grid>
                </Grid>
            </Grid>

        </Grid >
    );
}

export default About;