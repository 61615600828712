import { Box, Collapse, Divider, Grid, Link, List, ListItemButton, ListItemText } from "@mui/material";
import './Footer.css';
import logo from '../../assets/images/xward_logo_white.svg';
import ios from '../../assets/icons/ios_app_white.svg';
import android from '../../assets/icons/android_app.svg';
import ios_mobile from '../../assets/icons/ios_app_white_mobile.svg';
import android_mobile from '../../assets/icons/android_app_mobile.svg';
import { useState } from "react";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useHistory } from "react-router-dom";

const Footer = () => {
    const history = useHistory();

    return (
        <div id='footer'>
            <Grid container className='footerContent' spacing={{ md: 2, sm: 1 }}>
                <Grid item xs={12} className='links mobile'>
                    <MobileListLink />
                </Grid>
                <Grid item xs={12} md={4} sm={4} className='apps' textAlign={{ md: 'start', xs: 'start' }} >
                    <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
                    <Grid container spacing={{ xs: 1 }}>
                        <Grid item md={12} sm={12} xs={12} pb={3}>
                            <Link onClick={() => history.push('/')}><img src={logo} alt="Xward" /></Link>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} className='icons'>
                            <Grid container spacing={{ xs: 1 }} >
                                <Grid item xs={12} md={6} sm={6}>
                                    <Link onClick={() => history.push('/coming-soon')}><img src={ios} alt="Xward iOs app" className="image" /></Link>
                                </Grid>
                                <Grid item xs={12} md={6} sm={6}>
                                    <Link onClick={() => history.push('/coming-soon')}><img src={android} alt="Xward android app" className="image" /></Link>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} sm={12} textAlign={{ md: 'start', xs: 'start', sm: 'start' }} >
                                <p className="copyright">© 2020 XwardPay, Inc. All rights reserved.</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    </Box>
                    <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}>
                    <Grid container spacing={{ xs: 1 }}>
                        <Grid item md={12} sm={12} xs={12} className='icons'>
                            <Grid container spacing={{ xs: 2 }} >
                                <Grid item xs={12}>
                                    <Link onClick={() => window.location.href = 'https://apps.apple.com/us/app/xward-pay/id1627491504'}><img src={ios_mobile} alt="Xward iOs app" className="image"/></Link>
                                </Grid>
                                <Grid item xs={12}>
                                    <Link onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.xward.banking&hl=en&gl=US&pli=1'}><img src={android_mobile} alt="Xward android app" className="image"/></Link>
                                </Grid>
                        <Grid item xs={12} p={3}>
                            <Link onClick={() => history.push('/')}><img src={logo} alt="Xward"/></Link>
                        </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} sm={12} textAlign={{ md: 'start', xs: 'start', sm: 'start' }} >
                                <p className="copyright">© 2020 XwardPay, Inc. All rights reserved.</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={2} sm={2} textAlign={{ xs: 'start' }}>
                </Grid>
                <Grid item xs={12} md={2} sm={2} className='links desktop tablet' textAlign={{ xs: 'start' }}>
                    <p className="header">Sitemap</p>
                    <Link onClick={() => history.push('/')}>Home</Link>
                    <Link onClick={() => history.push('/plans')}>Plans</Link>
                    <Link onClick={() => history.push('/about')}>About</Link>
                    <Link onClick={() => history.push('/help')}>Help</Link>
                    <Link onClick={() => history.push('/coming-soon')}>Press</Link>
                </Grid>
                <Grid item xs={12} md={2} sm={2} className='links desktop tablet' textAlign={{ xs: 'start' }}>
                    <p className="header">Social Media</p>
                    <Link onClick={() => history.push('/coming-soon')}>Facebook</Link>
                    <Link onClick={() => history.push('/coming-soon')}>Instagram</Link>
                    <Link onClick={() => history.push('/coming-soon')}>LinkedIn</Link>
                    <Link onClick={() => history.push('/coming-soon')}>Twitter</Link>
                    <Link onClick={() => history.push('/coming-soon')}>YouTube</Link>
                </Grid>
                <Grid item xs={12} md={2} sm={2} className='links desktop tablet' textAlign={{ xs: 'start' }}>
                    <p className="header">Policies</p>
                    <Link onClick={() => history.push('/privacy-policy')}>Privacy Policy</Link>
                    <Link onClick={() => history.push('/terms-of-use')}>Terms of Use</Link>
                    <Link onClick={() => history.push('/terms-of-crypto-use')}>Terms of Crypto Use</Link>
                    <Link onClick={() => history.push('/prohibited-countries')}>Prohibited Countries</Link>
                    <Link onClick={() => history.push('/cookies-policy')}>Cookies Policy</Link>
                </Grid>
                <Grid item xs={12} md={12} textAlign={"center"}>
                <p className="copyright">Services advertised on this website are offered by Xward Pay Inc., a Canadian incorporated company with incorporation number BC1332850, located at Suite 2500, Park Place 666, Burrard St., Vancouver, BC, V6C 2X8, Canada, regulated by FINTRAC as a Money Service Business (MSB) with registration number M21791680 and its partners from time to time. Xward Pay Ltd., a limited liability company with incorporation number 14815227 and located at 124 City Road, EC1V 2NX, London, UK, is acting as payment agent of Xward Pay Inc.</p>
                </Grid>
            </Grid>
        </div>
    );
}


const MobileListLink = () => {
    const [openSitemap, setOpenSitemap] = useState(false);
    const [openSocialMedia, setOpenSocialMedia] = useState(false);
    const [openPolicies, setOpenPolicies] = useState(false);

    const handleSitemap = () => {
        setOpenSocialMedia(false);
        setOpenPolicies(false);
        setOpenSitemap(!openSitemap);
    };

    const handleSocialMedia = () => {
        setOpenSitemap(false);
        setOpenPolicies(false);
        setOpenSocialMedia(!openSocialMedia);
    };

    const handlePolicies = () => {
        setOpenSocialMedia(false);
        setOpenSitemap(false);
        setOpenPolicies(!openPolicies);
    };

    const history = useHistory();

    return (
        <>
            <List>
                <ListItemButton className='button' onClick={handleSitemap}>
                    <ListItemText className='text' primary="Sitemap" />
                    {openSitemap ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openSitemap} timeout="auto" unmountOnExit className='collapsedLinks'>
                    <Link onClick={() => history.push('/coming-soon')}>Home</Link>
                    <Link onClick={() => history.push('/plans')}>Plans</Link>
                    <Link onClick={() => history.push('/about')}>About</Link>
                    <Link onClick={() => history.push('/help')}>Help</Link>
                    <Link onClick={() => history.push('/coming-soon')}>Press</Link>
                </Collapse>
                <ListItemButton className='button' onClick={handleSocialMedia}>
                    <ListItemText className='text' primary="Social Media" />
                    {openSocialMedia ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openSocialMedia} timeout="auto" unmountOnExit className='collapsedLinks'>
                    <Link onClick={() => history.push('/coming-soon')}>Facebook</Link>
                    <Link onClick={() => history.push('/coming-soon')}>Instagram</Link>
                    <Link onClick={() => history.push('/coming-soon')}>LinkedIn</Link>
                    <Link onClick={() => history.push('/coming-soon')}>Twitter</Link>
                    <Link onClick={() => history.push('/coming-soon')}>YouTube</Link>
                </Collapse>
                <ListItemButton className='button' onClick={handlePolicies}>
                    <ListItemText className='text' primary="Policies" />
                    {openPolicies ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openPolicies} timeout="auto" unmountOnExit className='collapsedLinks'>
                    <Link onClick={() => history.push('/privacy-policy')}>Privacy Policy</Link>
                    <Link onClick={() => history.push('/terms-of-use')}>Terms of Use</Link>
                    <Link onClick={() => history.push('/cookies-policy')}>Cookies Policy</Link>
                </Collapse>
            </List>
        </>
    );
}

export default Footer;