import { Box, Grid, Link, Hidden } from "@mui/material";
import "./Download.css";
import hand from "../../../assets/images/card-2.png";
import ios from "../../../assets/icons/ios_app.svg";
import android from "../../../assets/icons/android_app.svg";
import ios_mobile from "../../../assets/icons/ios_app_white_mobile.svg";
import android_mobile from "../../../assets/icons/android_app_mobile.svg";
import Title from "../../shared/Title/Title";
import SubTitle from "../../shared/SubTitle/SubTitle";

const Download = () => {
  return (
    <div id="download">
      <Grid container className="downloadGrid" spacing={{ md: 2, sm: 1 }}>
        <Hidden mdDown={true}>
          <Grid item xs={12} md={6} sm={6} textAlign="center">
            <img src={hand} alt="Xward" className="hand" />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          md={6}
          sm={12}
          className="links desktop tablet"
          textAlign={{ xs: "center" }}
        >
          <Box className="downloadSpacer">
            <Title content="Download the app to get started" align="left" />
          </Box>
          <Box className="downloadSpacer">
            <SubTitle content="We’ve put in all our experience into creating the most convenient mobile app. Simple transfers, paying utility bills, a functional statement, and card settings which meant going to the bank office are now in your pocket!" />
          </Box>
          <Box
            className="downloadSpacer"
            sx={{ display: { xs: "none", sm: "block", md: "block" } }}
          >
            <Grid container>
              <Grid item sm={6}>
                <Link>
                  <img src={ios} alt="Xward iOs app" height="100%" />
                </Link>
              </Grid>
              <Grid item sm={6}>
                <Link>
                  <img src={android} alt="Xward android app" height="100%" />
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Box
            className="downloadSpacer"
            sx={{ display: { xs: "block", sm: "none", md: "none" } }}
          >
            <Link>
              <img src={ios_mobile} alt="Xward iOs app" />
            </Link>
            <Link>
              <img src={android_mobile} alt="Xward android app" />
            </Link>
          </Box>
        </Grid>
        <Hidden mdUp={true}>
          <Grid item xs={12} md={6} sm={12} textAlign="center">
            <img src={hand} alt="Xward" className="hand" />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default Download;
