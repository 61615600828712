import { Box, Grid } from "@mui/material";
import Download from "./Download/Download";
import OurPlans from "./OurPlans/OurPlans";
import Phone from "./Phone/Phone";
import SaveTheOcean from "./SaveTheOcean/SaveTheOcean";
import Solutions from "./Solutions/Solutions";
import './Landing.css';

const Landing = () => {
    return (
        <Grid container className="LandingTop" id="LandingTop">
            <Grid item xs={12} className="alignChildren">
                <Phone />
                <Download />
                <OurPlans />
            </Grid>
        </Grid>
    );
}

export default Landing;