import { Typography } from "@mui/material";
import { useEffect } from "react";
import './Cookies.css';

const Cokies = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id="cookies">
            <div className="cookiesBox">
                <Typography className="title">Cookies Policy</Typography>
                This Cookie Policy applies to all of our websites (the “Website”). When we refer to any of our websites, we mean any website or mobile application operated by or on behalf of XWARD PAY or its subsidiaries and affiliates, regardless of how you access the network. This Cookie Policy forms part of and is incorporated into our website Terms and Conditions.<br /><br />
                By accessing the website, you agree that this Cookie Policy will apply whenever you access the website on any device.<br /><br />
                Any changes to this policy will be posted here. We reserve the right to vary this Cookie Policy from time to time and such changes shall become effective as soon as they are posted. Your continued use of the website constitutes your agreement to all such changes.<br /><br />
                This policy explains how cookies are used on our website in general – and, below, how you can control the cookies that may be used on these sites (not all of them are used on every site).
                <Typography className="subtitle">What are cookies and how do we use them?</Typography>
                Cookies are small text files stored on your computer by your browser. They’re used for many things, such as remembering whether you’ve visited the site before, so that you remain logged in – or to help us work out how many new website visitors we get each month. They contain information about the use of your computer but don’t include personal information about you (they don’t store your name, for instance).<br /><br />
                We use cookies to provide the services and features offered on our websites, and to continually enhance the user experience.<br /><br />
                We may also use Google Analytics to track and analyze our site traffic in order to improve our website functionality by collecting data regarding how, when and where people use or access our website. Google Analytics does not collect personally identifiable data.
                <Typography className="title">How can you manage cookies?</Typography>
                <Typography className="subtitle">Removing cookies from your device</Typography>
                You can delete all cookies that are already on your device by clearing the browsing history of your browser. This will remove all cookies from all websites you have visited.<br /><br />
                Be aware though that you may also lose some saved information (e.g. saved login details, site preferences).
                <Typography className="subtitle">Managing site-specific cookies</Typography>
                For more detailed control over site-specific cookies, check the privacy and cookie settings in your preferred browser.
                <Typography className="subtitle">Blocking cookies</Typography>
                You can set most modern browsers to prevent any cookies being placed on your device, but you may then have to manually adjust some preferences every time you visit a site/page. And some services and functionalities may not work properly at all (e.g. profile logging-in).
                <Typography className="subtitle">Managing our analytics cookies</Typography>
                You can manage your preferences concerning cookies when you enter our Website.<br /><br />
                This Cookie Policy was last updated 10th March 2022.
            </div>
        </div>
    );
};

export default Cokies;
