import { IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
    value: string,
    onChange: (e: any) => void;
    clear: () => void;
}

const SearchInput = ({value, onChange, clear}: Props) => {

    return (
        <Paper
            id="searchInput"
            component="form"
            sx={{ padding: '2px 4px', display: 'flex', alignItems: 'center' }}
        >
            <IconButton sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
            <InputBase
                value={value}
                onChange={onChange}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Describe your issue"
            />
            <IconButton sx={{ p: '10px' }} aria-label="search" onClick={clear}>
                <ClearIcon />
            </IconButton>
        </Paper>
    );
}

export default SearchInput;