import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import faq from './slices/faq/faq.slice';

const appReducer = combineReducers({ faq });

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
	return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>

export default rootReducer;
