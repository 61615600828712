import { Typography } from "@mui/material";
import { useEffect } from "react";
import './ProhibitedCountries.css';

const ProhibitedCountries = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id="terms">
            <div className="termsBox">
                <Typography className="title">Prohibited Countries</Typography>
                <ul>
                    <li>
Afghanistan	
</li>
                    <li>
Belarus	
</li>
                    <li>
Burma	
</li>
                    <li>
Central African Republic
</li>
                    <li>
China	
</li>
                    <li>
Ivory Coast	
</li>
                    <li>
Cuba	
</li>
                    <li>
Congo, Dem. Rep. of
</li>
                    <li>
Eritrea	
</li>
                    <li>
Haiti	
</li>
                    <li>
Iran
</li>
                    <li>
Iraq	
</li>
                    <li>
Lebanon	
</li>
                    <li>
Liberia	
</li>
                    <li>
Libya
</li>
                    <li>
North Korea	
</li>
                    <li>
Russia
</li>
                    <li>
Somalia	
</li>
                    <li>
South Sudan	
</li>
                    <li>
Sri Lanka
</li>
                    <li>
Sudan	
</li>
                    <li>
Syria	
</li>
                    <li>
Venezuela	
</li>
                    <li>
Vietnam
</li>
                    <li>
Yemen
</li>
                    <li>
Zimbabwe	
</li>
                </ul>
            </div>
        </div>
    );
};

export default ProhibitedCountries;
