import { Typography } from "@mui/material";
import './SubTitle.css'

interface Props {
    content: string;
}

const SubTitle = ({content}: Props) => {

    return (
        <Typography component='h6' id='subTitle' className="sectionSubTitle">{content}</Typography>
    );
}

export default SubTitle;