import { Typography } from "@mui/material";
import { useEffect } from "react";
import './TermsCrypto.css';

const TermsCrypto = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id="terms">
            <div className="termsBox">
                <Typography className="title">TERMS AND CONDITIONS DIGITAL ASSETS</Typography>
<br/><br/>
                This agreement (the “Agreement”) is for customers who are using the following services (the "Digital Currency Services" or “Services”):
                <ul>
                    <li>
                    Provision of one or more custodial digital currency wallets (the "Digital Currency Wallet(s)") enabling you to store, track, transfer, and manage your balances of certain supported digital currencies (collectively "Digital Currency", “Digital Currencies” or “Crypto Assets”); and
                    </li>
                    <li>
                    Provision of a Digital Currency exchange service enabling you to obtain prices for your purchases and sales of Digital Currencies and carry out any such purchases or sales on the Platform (the “Digital Currency Exchange Service”).
                    </li>
                </ul>
                <br/><br/>
                This Agreement is between (i) you and (ii) Xward Pay Inc. (hereinafter “Xward”, “we” or “us”), a Canadian Incorporated Company with incorporation number BC1332850, located at Suite 2500, Park Place 666, Burrard St., Vancouver, BC, V6C 2X8, Canada, regulated by FINTRAC as a Money Service Business (MSB) with registration number M21791680.<br/>
                <br/><br/>
                These Terms do not govern payment or other regulated services related to your Account in the Platform. To use our Digital Currency Services, you must have a verified Account in the Platform. <br/>
                <br/><br/>
                By signing up to use the Digital Currency Services any of our associated websites, platform or mobile applications (collectively the "Platform"), you agree that you have read, understood, and accept all of the terms and conditions contained in this Agreement, as well as our Privacy Policy.<br/><br/>
                <br/><br/>
                You should be aware that the risk of loss in trading or holding Digital Currencies can be substantial. As with any asset, the value of Digital Currencies can go up or down and there can be a substantial risk that you lose money buying, selling, holding, or investing in digital currencies. <br/>
                <br/><br/>
                Digital Currencies are not like the money or e-money in your Account. They are decentralised and in Canada they are regulated only for the purposes of anti-money laundering and counter-terrorist financing. <br/>
                <br/><br/>
                When you use our Digital Currencies Services, you will not be protected by any compensation and if you have a complaint it is not likely to fall within the scope of the jurisdiction of the Financial Ombudsman Service. <br/>
                <br/><br/>
                We do not provide any investment advice relating to our Digital Currencies Services. You should carefully consider whether your personal situation and the financial risks you are willing to take mean that you should not buy Digital Currencies. You may want to speak to an independent financial adviser. <br/>
                <br/><br/>
                <Typography className="subtitle">Digital Currency Services</Typography>
                Your Digital Currency Wallet enables you to send Digital Currency to, and request, receive, and store Digital Currency from, other users or third parties outside our Platform, by giving instructions through the Platform (each such transaction is a "Digital Currency Transaction").
                <br/><br/>
                The Digital Currency Exchange Service enables you to buy Digital Currency on our Platform using:
                <ul>
                    <li>
                    E-Money from your E-Money Wallet held with the Payment Service Provider;
                    </li>
                    <li>
                    Other types of Digital Currency in your Digital Currency Wallet.
                    </li>
                </ul>

                Conversely, when you sell Digital Currency on the Platform, you may elect to receive:
                <ul>
                    <li>
                    E-Money into your E-Money Wallet;
                    </li>
                    <li>
                    Other types of Digital Currency in your Digital Currency Wallet.
                    </li>
                </ul>
                <br/><br/>
                <Typography className="subtitle">Transaction Fulfilment</Typography>
                We will make reasonable efforts to fulfil all purchases of Digital Currency, but in some circumstances, we may be unable to. If this is the case, we will notify you and seek your approval to re-attempt the purchase at the current Exchange Rate.
                <br/><br/>
                <Typography className="subtitle">Conversion Fees</Typography>
                Each purchase or sale of Digital Currency is subject to a fee (a "Conversion Fee"). The applicable Conversion Fee will be displayed to you on the Platform prior to each transaction and is stated in each receipt we issue to you. We may adjust our Conversion Fees at any time without the need of sending you a notification. 
                <br/><br/>
                <Typography className="subtitle">Exchange Rates</Typography>
                Each purchase or sale of Digital Currency is also subject to the Exchange Rate for the given transaction (the “Exchange Rate”). The Exchange Rate means the price of a given supported Digital Currency in fiat currency as quoted on the Site. The Exchange Rate is stated either as a "Buy Price" or as a "Sell Price", which is the price at which you may buy or sell Digital Currency, respectively.
                <br/><br/>
                You acknowledge that the Buy Price Exchange Rate may not be the same as the Sell Price Exchange Rate at any given time, and that we may add a margin or ‘spread’ to the quoted Exchange Rate. You agree to accept the Exchange Rate when you authorise a transaction. We do not guarantee the availability of any Exchange Rate. We do not guarantee that you will be able to buy and/or sell your Digital Currency on the open market at any particular price or time.
                <br/><br/>
                <Typography className="subtitle">Authorisations; Reversals; Cancellations</Typography>
                By clicking the ‘Buy’ or ‘Sell’ button on the Platform, you are authorising us to initiate the transaction at the quoted Buy Price or Sell Price and agree to any associated Conversion Fees and Exchange Fees and any other fees.
                <br/><br/>
                You cannot cancel, reverse, or change any transaction marked as complete or pending. If your payment is not successful or if your payment method has insufficient funds, you authorise us, in our sole discretion, to cancel the transaction. You are responsible for maintaining an adequate balance in order to avoid overdraft, insufficient funds, or similar fees charged by your payment provider. We reserve the right to suspend access to the Services until such insufficient payment is addressed.
                <br/><br/>
                We may refuse your instructions to deposit or withdraw Digital Assets when: 
                <ul>
                    <li>
                    There are not enough Digital Assets available in your Digital Assets Wallet. 
                    </li>
                    <li>
                    We, or one of the exchanges or third parties we work with to provide our Services, are not available (for example, due to service disruption or scheduled maintenance). 
                    </li>
                    <li>
                    We have good reason to suspect that your instruction was for illegal purposes (for example, to commit fraud). 
                    </li>
                    <li>
                    We have good reason to believe that your instruction could badly affect our reputation or goodwill.
                    </li>
                </ul>
                <br/><br/>
                <Typography className="subtitle">Digital Currency Transactions</Typography>
                We will process Digital Currency Transactions in accordance with the instructions we receive from you. You should verify all transaction information prior to submitting instructions to us. We do not guarantee the identity of any user, receiver, requestee or other third party and we will have no liability or responsibility for ensuring that the information you provide is accurate and complete.
                <br/><br/>
                Digital Currency Transactions cannot be reversed once they have been broadcast to the relevant Digital Currency network.
                <br/><br/>
                We may charge network fees (“miner fees”) to process a Digital Currency Transaction on your behalf. We will calculate the miner fees at our discretion, although we will always notify you of the miner fees at (or before) the time you authorise the Digital Currency Transaction. Miner fees for each individual transaction will be disclosed to you at the time of purchase on the checkout page. When you or a third party sends Digital Currency to a Digital Currency Wallet from an external wallet not hosted by us, the person initiating the transaction is solely responsible for executing the transaction properly, which may include, among other things, payment of miner fees in order for the transaction to be completed successfully. Non-payment of miner fees may cause your transaction to remain in a pending state outside of our control and we are not responsible for delays or loss incurred as a result of an error in the initiation of the transaction and have no obligation to assist in the remediation of such transactions.
                <br/><br/>
                Once submitted to a Digital Currency network, a Digital Currency Transaction will be unconfirmed for a period of time pending sufficient confirmation of the transaction by the Digital Currency network. A Digital Currency Transaction is not complete while it is in a pending state. Digital Currency associated with Digital Currency Transactions that are in a pending state will be designated accordingly and will not be included in your Digital Currency Wallet balance or be available to conduct Digital Currency Transactions until confirmed by the network.
                <br/><br/>
                We may also refuse to process or cancel any pending Digital Currency Transaction as required by law, regulation or any court or other authority to which we are subject in any jurisdiction, for instance, if there is suspicion of money laundering, terrorist financing, fraud, or any other financial crime.
                <br/><br/>
                To meet our regulatory obligations, we also may be required to: 
                <ul>
                    <li>
                    Collect certain information from you before or after processing your withdrawal to an external wallet, and share that information to the host of that external wallet. 
                    </li>
                    <li>
                    Collect and verify information about a deposit from an external wallet, and carry out a risk assessment on the deposit, before making it available to you.  
                    </li>
                    <li>
                    We may freeze and retain a deposit or withdrawal, return an attempted withdrawal to you, or return an attempted deposit to its source, where: 
                    
                <ul>
                    <li>
                    You do not provide the information we need to process or release a deposit or withdrawal, or we have good reason to suspect that information you have provided is incorrect or false. 
                    </li>
                    <li>
                    We receive insufficient information about a deposit from an external wallet, or the information does not match our records about who the intended beneficiary of the deposit is. 
                    </li>
                    <li>
                    The host of an external wallet rejects and returns your withdrawal to us. 
                    </li>
                </ul>
                    </li>
                </ul>

                You may be charged any network and service fees associated with returning a deposit to its source, or returning a withdrawal to you where it is rejected by the host of an external wallet. 
                <br/><br/>
                <Typography className="subtitle">Supported Digital Currencies </Typography>
                Our Digital Currency Services are available only in connection with those digital currencies that we support (“Supported Digital Currencies”), and this may change from time to time.
                <br/><br/>
                Under no circumstances should you attempt to use your Digital Currency Wallet to store, send, request, or receive digital currencies in any form that we do not support. We assume no responsibility or liability in connection with any attempt to use your Digital Currency Wallet for digital currencies that we do not support. You acknowledge and agree that we bear no responsibility and are not liable for any unsupported asset that is sent to a wallet associated with your Account. If you send an unsupported asset to a wallet associated with your Account, then you will lose that asset. 
                <br/><br/>
                <Typography className="subtitle">Ending support of a Digital Currency</Typography>
                
                We may, in our sole discretion, and at any time, terminate support for any Digital Currency. You will be informed in the Platform if such situation may happen. 
                <br/><br/>
                <Typography className="subtitle">Operation of Digital Currency Protocols</Typography>
                We do not own or control the underlying software protocols which govern the operation of Digital Currencies supported on our platform. Generally, the underlying protocols are open source and anyone can use, copy, modify, and distribute them. We assume no responsibility for the operation of the underlying protocols and we are not able to guarantee the functionality or security of network operations. You acknowledge and accept the risk that underlying software protocols relating to any Digital Currency you store in your Digital Currency Wallet may change.
                <br/><br/>
                In particular, the underlying protocols are likely to be subject to sudden changes in operating rules (including “forks”). Any such material operating changes may materially affect the availability, value, functionality, and/or the name of the Digital Currency you store in your Digital Currency Wallet. We do not control the timing and features of these material operating changes. It is your responsibility to make yourself aware of upcoming operating changes and you must carefully consider publicly available information and information that may be provided by us in determining whether to continue to transact in the affected Digital Currency using your Account. In the event of any such operational change, we reserve the right to take such steps as may be necessary to protect the security and safety of assets held on the Platform, including temporarily suspending operations for the involved digital currency(ies), and other necessary steps.
                <br/><br/>
                You acknowledge and accept the risks of operating changes to Digital Currency protocols and agree that we are not responsible for such operating changes and not liable for any loss of value you may experience as a result of such changes in operating rules. You acknowledge and accept that we have sole discretion to determine our response to any operating change and that we have no responsibility to assist you with unsupported currencies or protocols
                <br/><br/>
                <Typography className="subtitle">Fungibility of Certain Digital Currencies</Typography>
                
                You acknowledge and agree that we may hold Supported Digital Currencies in your Digital Currency Wallets in a variety of different ways, including across multiple blockchain protocols, such as layer two networks, alternative layer one networks, or side chains. In connection with its holding of Supported Digital Currencies in your Digital Currency Wallets, we may transfer such Digital Currencies off of the primary blockchain protocol and hold such Digital Currencies on shared blockchain addresses, controlled by us, on alternative blockchain protocols in forms compatible with such protocols or on cold wallets. You agree that all forms of the same Digital Currencies that are held and made available across multiple blockchain protocols may be treated as fungible and the equivalent of each other, without regard to (a) whether any form of such Digital Currencies is wrapped or (b) the blockchain protocol on which any form of such Digital Currencies is stored.
                <br/><br/>
                <Typography className="subtitle">Digital Currency Title</Typography>
                
                All Digital Currencies held in your Digital Currency Wallet are assets held on a custodial basis. Among other things, this means:
                <ul>
                    <li>
                    Title to Digital Currency shall at all times remain with you and shall not be transferred to us. As the owner of Digital Currency in your Digital Currency Wallet, you shall bear all risk of loss of such Digital Currency. We shall not have any liability for fluctuations in the fiat currency value of Digital Currency held in your Digital Currency Wallet.
                    </li>
                    <li>
                    None of the Digital Currencies in your Digital Currency Wallet are our property. You appoint us as your nominee for the purpose of holding your Digital Assets. This means we hold the legal title to, and you are the beneficial owner of, the amount of cryptoassets we hold on your behalf.  We do not represent or treat assets in a user’s Digital Currency Wallets as belonging to us. Except as required by a facially valid court order, or except as provided herein, we will not sell, transfer, loan, hypothecate, or otherwise alienate Digital Currency in your Digital Currency Wallet unless instructed by you or compelled by a court of competent jurisdiction to do so.
                    </li>
                    <li>
                    You control the Digital Currencies held in your Digital Currency Wallet. At any time, subject to outages, downtime, protocol requirements, time to conduct blockchain operations to fulfill your request, and other applicable policies, you may withdraw your Digital Currency by sending it to a different blockchain address controlled by you or a third party.
                    </li>
                </ul>

                <Typography className="subtitle">Digital Currency Custody</Typography>
                Clients’ Digital Currencies are usually hold in segregated on-chain wallets and attributable to the Client on client-specific addresses (Separated Custody).
                <br/><br/>
                The Client acknowledges and agrees that the Platform may transfer Digital Currencies from Separated Custody to collective custody in the context of an instruction from the Client, in particular in the following cases: 

                <ul>
                    <li>
                    If the Client places an order, in which case the Digital Currencies may be transferred to collective custody for the purpose of executing the Order; or
                    </li>
                    <li>
                    If the Client requests to withdraw its Digital Currencies from the Platform, in which case the Digital Currencies may be transferred to collective custody for the purpose of processing the withdrawal request; or
                    </li>
                    <li>
                    If it is required for operational purposes. 
                    </li>
                </ul>

                <Typography className="subtitle">Wallet Analysis and Orders Execution</Typography>
                We will proceed to a wallet analysis of the Crypto Assets you will be sending to the Platform. In the event of “tainted” virtual currencies or address(es) from which they originate, you understand and agree that the Platform will be under the obligation to report your tainted address and/or Crypto Assets to the competent authorities and that the funds might be frozen.
                <br/><br/>
                The wallet analysis may ultimately result in us taking the following actions: 

                <ul>
                    <li>
                    Freezing a deposit or withdrawal, and asking you for additional information before processing or releasing a deposit or withdrawal. 
                    </li>
                    <li>
                    Blocking deposits to, or withdrawals from, certain external wallet addresses. 
                    </li>
                    <li>
                    Freezing and retaining deposits from a source that has been identified as being sanctioned, until applicable sanctions change.
                    </li>
                </ul>

                When a deposit or withdrawal is blocked, frozen, or returned, we will store and process this information in accordance with our legal and regulatory obligations. 
                <br/><br/>
                <Typography className="subtitle">Disclaimer</Typography>
                
You acknowledge that buying and selling Digital Currencies is risky. you should only purchase Digital Currencies if you can afford to lose the entire amount of your purchase. you acknowledge there is a risk that the value of the Digital Currencies decreases to zero. 
<br/><br/>
You accept all risks arising from transferring your Digital Currencies to any third-party, including the risk that such third-party misappropriates your Digital Currencies. You are responsible for conducting your own due diligence in respect of any third parties you elect to send Digital Currencies to. You further accept all risks associated with sending Digital Currencies to fraudulent or otherwise illegal schemes. 
<br/><br/>
Despite making various Digital Currencies available on the Platform, we make no representations or warranties in respect of such Digital Currencies, or that they have, or will have any specific utility, functionality or features. 
<br/><br/>
You acknowledge that the Platform is not able to confirm the identity of the owners of any external digital wallet addresses for any Digital Currencies. You accept all risks with any transfer, or attempted transfer, of Digital Currencies to any third-party. As Digital Currencies transactions on the blockchain cannot be reversed by us, it is your responsibility to ensure the accuracy of any wallet addresses you specify for withdrawals.  
<br/><br/>
You acknowledge that the underlying blockchain technology of the digital assets may undergo a divergence, resulting in the formation of new Digital Currencies (a “fork”). A fork may impact the value, functionality and other characteristics of the Digital Currencies. We make no representation or warranty as to whether we will support a fork arising from any of the Digital Currencies. You agree that the Platform shall not be responsible or liable for claiming, issuing, storing or holding any Digital Currencies resulting from a fork for or on your behalf.  
<br/><br/>
You acknowledge and agree that the Platform may elect not to support a fork arising from any of the Digital Currencies and that in such circumstances, you may not be able to claim any new Digital Currencies resulting from a fork. 
<br/><br/>
The Platform may decide which Digital Currencies are supported and offered as part of the Services. If the Platform decides to no longer offer certain Digital Currencies, the Client is obliged to provide the Platform with a whitelisted address to enable the Platform to send any remaining Digital Currencies to the Client. If the Client fails to deliver such an address, the Client accepts that the Platform may keep such funds. 
<br/><br/>
                <Typography className="subtitle">Acceptance of Risks</Typography>

                There are many risks associated with Digital Currencies, some of which are independent of the Services (and that are present in other Digital Currencies business models), for which the Platform will not be responsible and will not have any liability for, including the following Risks:

                <ul>
                    <li>
                    Blockchain transactions are final, irreversible and irrecuperable. You must be careful not to transfer Digital Currencies from your Digital Currency Wallet to other external wallets, untrustworthy businesses or to people you do not personally know. 
                    </li>
                    <li>
                    You are responsible for ensuring that any Digital Currencies transfer that you conduct are with a trustworthy person or business. Any Digital Currencies transfer that you perform is final, irreversible and irrecuperable. You are responsible for ensuring that any Digital Currencies that you conduct are with the correct person or business.  
                    </li>
                    <li>
                    You are responsible for securing your device, email account, phone number, and other methods of communication that you use with our Services.
                    </li>
                    <li>
                    There are some Digital Currencies that have address formats that overlap (e.g. Bitcoin and certain Bitcoin forks). If you send a different Digital Currency to a wallet address not intended for that Digital Currency, then it will result in a permanent loss. Always take care when conducting Digital Currencies transactions, and if you are in doubt, you should contact our customer support to receive technical support about blockchains and address formats. 
                    </li>
                    <li>
                    We attempt to provide support for popular Digital Currencies (e.g. Bitcoin, Ethereum and USDC) but do not guarantee that we will continue to support any particular Digital Currency. In the event that we are forced to disable or remove a Digital Currency we will attempt to provide notice in advance.
                    </li>
                </ul>

                The Platform may decide which Crypto Assets are supported and offered as part of the Services. If the Platform decides to no longer offer certain digital assets, the Client is obliged to provide the Platform with a whitelisted address to enable the Platform to send any remaining digital assets to the Client. If the Client fails to deliver such an address, the Client accepts that the Platform may keep such funds. 
                <br/>
                <br/>
                <Typography className="subtitle">Taxes</Typography>
                It is your sole responsibility to determine whether, and to what extent, any taxes apply to any transactions you conduct through the Services, and to withhold, collect, report and remit the correct amount of tax to the appropriate tax authorities. Your transaction history is available through your Account.
                <br/>
                <br/>
                <Typography className="subtitle">Acceptance and Entry into Form</Typography>
                At the time when a Business Relationship is effectively opened or the Client starts or continues to use the Services, the Client acknowledges and agrees to the Terms in force at that time. 
                <br/>
            </div>
        </div>
    );
};

export default TermsCrypto;
