import { Typography } from "@mui/material";
import './Title.css'

interface Props {
    content: string;
    align?: string 
}

const Title = ({content, align}: Props) => {

    return (
        <Typography component='h2' id='title' className={align === "left" ? "sectionTitleLeft" : "sectionTitle"}>{content}</Typography>
    );
}

export default Title;