import { Collapse, Grid, List, ListItemButton, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Faq, FaqList } from "../../../services/slices/faq/faq.types";
import './list.faq.css';

interface Props {
    list: Faq[],
    selectedHeader: string
}

const FAQList = ({ list, selectedHeader }: Props) => {
    const [open, setOpen] = useState<string>(selectedHeader);
    const [subOpen, setSubOpen] = useState<number>(0);
    const [faq, setFaq] = useState<Faq>(null);
    const [content, setContent] = useState<FaqList>(null);

    useEffect(() => {
        const item = list.filter(item => item.name === open);
        setFaq(item[0]);
        setContent(item[0].faqs[subOpen]);
    }, [open, subOpen, list])

    return (
        <>
            <Grid item xs={12} md={3} sm={3} className='navigation'>
                <List component="nav">
                    {list && list.map(item => (
                        <div key={item.name}>
                            <ListItemButton onClick={() => setOpen(item.name)} className='button'>
                                <ListItemText primary={<Typography className={`menuTitle ${open === item.name ? 'selected' : ''}`}>{item.name}</Typography>} />
                            </ListItemButton>
                            {faq && open === item.name && <SubMenu open={open} subOpen={subOpen} data={faq} onClick={setSubOpen} />}
                        </div>
                    ))}
                </List>
            </Grid>
            <Grid item xs={12} md={9} sm={9} className='content'>
                {content && <>
                    <Typography className='title'>{open}</Typography>
                    <Typography className='subtitle'>{content.question}</Typography>
                    <div className='answer' dangerouslySetInnerHTML={{ __html: content.answer }} />
                </>}
            </Grid>
        </>
    );
}

interface SubMenuProps {
    open: string;
    subOpen: number;
    data: Faq;
    onClick: (index: number) => void;
}

const SubMenu = ({ open, subOpen, data, onClick }: SubMenuProps) => {

    return (
        <Collapse in={open === data.name} timeout="auto" unmountOnExit className="collapse">
            <List component="div" disablePadding>
                {data.faqs.map((item, index) => (
                    <ListItemButton key={index} onClick={() => onClick(index)}>
                        <ListItemText primary={<Typography className={`menuSubTitle ${index === subOpen ? "selected" : ''}`}>{item.question}</Typography>} />
                    </ListItemButton>
                ))}
            </List>
        </Collapse>
    );
}

export default FAQList;