import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import './Privacy.css';

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id="privacy">
            <div className="privacyBox">
                <Typography className="title">Privacy Policy</Typography>
<br/><br/>
                Last modified: 10/10/2023
                <br/><br/>
Welcome to Xward Pay (“Xward”) Privacy Policy. Please take a moment to read it carefully before providing us with any personal information about you or any other person.
<br/><br/>
Xward is operated by Xward Pay Inc., (hereinafter “Xward”, “we”, “our” or “us”) and its partners from time to time. For the purposes of data protection law, Xward is a data controller in respect of the personal data we receive from you, or otherwise collect about you, and we are responsible for ensuring that the use of your personal data is compliant with applicable data protection laws (“General Data Protection Regulation”). As the controller of your personal data, we determine the “means” and the “purposes” of any processing activities that are carried out by us.
<br/><br/>
                <Typography className="subtitle">Background</Typography>
                
We need to collect and use certain types of information about the physical and legal persons that wish to participate in our services.
<br/><br/>
The personal information must be collected and dealt with appropriately in accordance with our AML Policy, this Privacy Policy and the GDPR and international standards.
<br/><br/>
In accordance with the provisions of GDPR and international standards, any user who provides his information under the present policy confirms that he understands and accepts the reason for the gathering of information and consents to the processing of information for the purposes of money laundering prevention.
<br/><br/>
Any User is entitled to know who is responsible for the processing of his or her personal data.
<br/><br/>
Any data collected during the course of our business whether it is collected on paper, stored in a computer database, or recorded on other material is subject to this policy and is protected under the applicable law and the GDPR provisions regarding data control and processing.
<br/><br/>
<Typography className="subtitle">What Does This Policy Cover?</Typography>
This Privacy Policy applies only to your use of our platform. The platform may contain links to other websites. Please note that we have no control over how your data is collected, stored, or used by other websites and we advise you to check the privacy policies of any such websites before providing any data to them. 
<br/><br/>
                <Typography className="subtitle">What is Personal Data?</Typography>
                
Personal data is commonly defined as any information relating to an identifiable person who can be directly or indirectly identified in particular by reference to an identifier.
<br/><br/>
Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data covers information such as your name and contact details, but it also covers less obvious information such as identification numbers, electronic location data, and other online identifiers. 
<br/><br/>
                <Typography className="subtitle">What Data Do We Collect?</Typography>
We collect personal information in the course of its business with you when you apply for the use of our products and/or services. The information is collected with your consent, or as permitted or required by law.
<br/><br/>
Depending upon your use of our platform, the personal information that you provide or that we ask for and collect could include the following: 

                <ul>
                    <li>
                    Identity Data:
                <ul>
                    <li>
                    First name
                    </li>
                    <li>
                    Last name
                    </li>
                    <li>
                    Title
                    </li>
                    <li>
                    Date of birth and gender
                    </li>
                    <li>
                    Biometric information for facial recognition purposes
                    </li>
                    <li>
                    ID documents such as national ID card, passports, driving licences or other forms of ID documents
                    </li>
                </ul>
                    </li>
                    <li>
                    Identity Related Data:
                <ul>
                    <li>
                    Risk assessment
                    </li>
                    <li>
                    Compliance assessment
                    </li>
                </ul>
                    </li>
                    <li>
                    Contact Data:
                <ul>
                    <li>
                    Residence details
                    </li>
                    <li>
                    Billing address
                    </li>
                    <li>
                    Delivery address
                    </li>
                    <li>
                    Email address and telephone numbers
                    </li>
                    <li>
                    Proof of address documentation
                    </li>
                </ul>
                    </li>
                    <li>
                    Financial Data:
                <ul>
                    <li>
                    Bank account details
                    </li>
                    <li>
                    Payment card details
                    </li>
                    <li>
                    Amounts associated with accounts
                    </li>
                    <li>
                    External account details
                    </li>
                    <li>
                    Source of funds and related documentation
                    </li>
                </ul>
                    </li>
                    <li>
                    Transactional Data:
                <ul>
                    <li>
                    Details about incoming and outgoing payments
                    </li>
                    <li>
                    Other details of any transactions you enter into using our platform
                    </li>
                </ul>
                    </li>
                    <li>
                    Technical Data:
                <ul>
                    <li>
                    Internet connectivity data
                    </li>
                    <li>
                    IP address
                    </li>
                    <li>
                    Operator and carrier data
                    </li>
                    <li>
                    Login data
                    </li>
                    <li>
                    Browser type and version
                    </li>
                    <li>
                    Device type, category and model
                    </li>
                    <li>
                    Time zone setting and location data
                    </li>
                    <li>
                    Language data
                    </li>
                    <li>
                    Browser plug-in types and versions
                    </li>
                    <li>
                    Operating system and platform
                    </li>
                    <li>
                    Diagnostics data such as crash logs and any other data we collect for the purposes of measuring technical diagnostics, and
                    </li>
                    <li>
                    Other information stored on or available regarding the devices you allow us access to when you visit the platform
                    </li>
                </ul>
                    </li>
                    <li>
                    Profile Data:
                <ul>
                    <li>
                    Your username and password
                    </li>
                    <li>
                    Your identification number as our user
                    </li>
                    <li>
                    Requests by you for products or services
                    </li>
                    <li>
                    Your interests, preferences and feedback
                    </li>
                    <li>
                    Other information generated by you when you communicate with our customer support
                    </li>
                </ul>
                    </li>
                </ul>
                
This information may be collected by way of paper documents, scanned or faxed copies, telephone, emails, verbal interviews, face to face interactions, or data received through our platform. This information will include the information collected by us about yourself, and any other individual with whom you deal through us, which include counterparties or senders or beneficiaries of funds or services.
<br/><br/>
We collect this information through our employees, affiliates, and online resources, government agencies, or business partners, or consumer reporting agencies, or may be provided by yourself upfront when you approach us for applying to our services.
<br/><br/>
We do not process “Sensitive Data” except when it comes to biometric data processed for the purpose of uniquely identifying a natural person. 
<br/><br/>
<Typography className="subtitle">How Do We Use Your Personal Data?</Typography>
Under the Applicable law, we must always have a lawful basis for using personal data. This may be because the data is necessary for our provision of services to you and the performance of a contract with you, because you have consented to our use of your personal data, or because it is in our legitimate business interests to use it. Your personal data may be used for one of the following purposes:
<ul>
                    <li>
                    Providing and managing your access to our platform
                    </li>
                    <li>
                    Supplying our products and services to you
                    </li>
                    <li>
                    Communicating with you
                    </li>
                    <li>
                    Maintaining our own accounts and records
                    </li>
                    <li>
                    Complying with legal and regulatory obligations on our and your behalf; and
                    </li>
                    <li>
                    Analyzing your use of our website to enable us to continually improve it and your user experience
                    </li>
                </ul>
                <br/><br/>
                <Typography className="subtitle">Option Out Right</Typography>
                We may use your contact details and information related to your service preferences for marketing and solicitation purposes to send you offers or promotions of us or our affiliates. You may “opt out” or withdraw consent to disclosure of information for the purposes described in this section at any time by contacting us at compliance@xwardpay.io
                <br/><br/>
                <Typography className="subtitle">How Long Will We Keep Your Personal Data?</Typography>
                We will keep your personal data for such a period as is required for us to provide services to you, or such a longer period as we may be required to process the data to ensure our compliance with all relevant laws and regulatory obligations to which we are subject.
                <br/><br/>
                <Typography className="subtitle">How and Where Do We Store Your Personal Data?</Typography>
                Personal data is processed both manually and electronically and may be held in a number of different places in line with the operations of a typical business. The locations your personal data could be stored include, but are not limited to: 
                <ul>
                    <li>
                    Our databases and CRM
                    </li>
                    <li>
                    Emails on computers
                    </li>
                    <li>
                    Mobile phones and tablets
                    </li>
                    <li>
                    Contact address books
                    </li>
                    <li>
                    Business cards
                    </li>
                    <li>
                    Notebooks
                    </li>
                    <li>
                    Electronic documents on our file servers
                    </li>
                    <li>
                    Paper files in our offices
                    </li>
                    <li>
                    Cloud back-up and storage
                    </li>
                </ul>
                The security of your personal data is important to us, and to protect your data we use and maintain appropriate technical and organizational measures to protect your personal data and to prevent the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of or access to personal data transmitted, stored or otherwise processed. We have a framework of policies, procedures and training to cover information and cyber security, confidentiality and data protection.
                <br/><br/>
                <Typography className="subtitle">Sharing and Transfer of Personal Data</Typography>
                
We will only disclose personal data to third parties as is necessary to provide our services and/or to comply with your instructions. We may share your personal data with our affiliates and subsidiaries. We do not give or sell your personal information to third parties.
In addition, we may be legally required to share certain personal data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority.
<br/><br/>
If any of your personal data is required by a third-party, we will take steps to ensure that your personal data is handled safely, securely, and in accordance with your rights, our obligations, and the third-party’s obligations under the law.
<br/><br/>
As some of our external third parties are based outside of Canada, the processing of your personal data may involve a transfer of data outside of Canada.
<br/><br/>
Whenever we transfer your personal data out of Canada, we ensure a similar degree of protection is afforded to it by ensuring that the country to which we transfer your personal data has been deemed to provide an adequate level of protection for personal data.
<br/><br/>
<Typography className="subtitle">Confidentiality</Typography>
We maintain physical, electronic, and procedural safeguards to protect personal information against loss, theft, and any form of unauthorized access. Only those who are authorized employees, agents, or representatives that require access to your personal information in order to fulfill their job requirements will have access to the information. Our obligations will become effective only when the information is received by us physically or by its internal systems, but not while it is in transmission out of or to our physical possession or internal systems; because data exchange over the internet or the telephone system or physical mail cannot be guaranteed to be perfectly secure, any personal information you submit to us or access electronically or over the telephone is done at your own risk, and we does not guarantee or warrant the security of information so transmitted to us. You should exercise caution to prevent any third parties, or parties fraudulently posing as us, from unlawfully intercepting your transmissions or causing you to disclose personal information.
<br/><br/>
<Typography className="subtitle">Consent</Typography>
By using our platform, you signify your acceptance of our privacy policy.
<br/><br/>
This Privacy Policy provides our intentions in regard to your personal information collected by us from you. By providing us with personal information, we will assume your consent to our collection, use and disclosure of such information for the purposes described in this Privacy Policy wherever applicable. Please note that your consent implies the consent to use your email addresses, postal addresses, and other contact resources that you provide to us to communicate with you.
<br/><br/>
Your consent may be given in various ways, such as: 
<ul>
                    <li>
                    Orally, electronically or on a document in any form
                    </li>
                    <li>
                    Implied by voluntarily choosing to use our services; by not withdrawing consent to use your personal information for any identified purpose
                    </li>
                    <li>
                    By not using an “opt out” option provided for the specified purposes in this statement, if any
                    </li>
                </ul>
Such consent may be given by you directly or by your legal representative.
<br/><br/>
Withdrawal of your consent will be entirely at your choice at any time, and will be effective, except in cases where we are subject to legal, contractual, regulatory, governmental, and/ or statutory obligations for record maintenance and disclosure; and except in case of transactions already initiated which need the use of your personal information for fulfillment. Note that in case of such withdrawal of consent we may no longer be able to provide certain of our products or services which require your personal information.
<br/><br/>
                <Typography className="title">Your Rights</Typography>
Under this Privacy Policy, you have the following rights:

                <ul>
                    <li>
                    The right to be informed about our collection and use of your personal data
                    </li>
                    <li>
                    The right to access the personal data we hold about you – you have the right to obtain confirmation as to whether we process personal data about you and certain information about how and why we process your personal data. 
                    </li>
                    <li>
                    The right to have your personal data amended or rectified if any of your personal data held by us is inaccurate or incomplete
                    </li>
                    <li>
                    The right to erasure (also known as the right to be forgotten), i.e. the right to ask us to delete or otherwise dispose of any of your personal data that we have
                    </li>
                    <li>
                    The right to restrict the processing of your personal data
                    </li>
                    <li>
                    The right to object to us using your personal data for a particular purpose or purposes
                    </li>
                    <li>
                    The right to data portability. This means that, if you have provided personal data to us directly, we are using it with your consent or for the performance of a contract or the provision of services, and that data is processed using automated means, you can ask us for a copy of that personal data to re-use with another service or business
                    </li>
                    <li>
                    The right to file a complaint. If you reside in an EEA Member State, you have the right to make a complaint about the way we process your personal data to the supervisory authority in the EEA member state of your habitual residence, place of work or place of the alleged infringement
                    </li>
                </ul>
               
For more information about our use of your personal data or exercising your rights as outlined above, or in the event that you wish to make a complaint, please contact us at compliance@xwardpay.io 
<br/><br/>

                <Typography className="subtitle">Changes to this Privacy Policy</Typography>
We may change this Privacy Policy from time to time. This may be necessary, for example, if the law changes, or if we change our business in a way that affects personal data protection.
<br/><br/>
Any changes will be posted on our platform and you will be deemed to have accepted the terms of the Privacy Policy on your first use of our website following the alterations. 
<br/><br/>
            </div>
        </div>
    );
};

export default Privacy;
