import { Grid, Typography } from "@mui/material";
import Carousel from 'nuka-carousel';
import React from "react";
import phone1 from "../../../assets/phone/01.1.png";
import phone2 from "../../../assets/phone/01.2.png";
import phone3 from "../../../assets/phone/01.3.png";
import phone4 from "../../../assets/phone/01.4.png";
import phone5 from "../../../assets/phone/01.5.png";
import useWindowDimensions from "../../shared/helpers/Dimension";
import './Phone.css'

const Phone = () => {

    const { width } = useWindowDimensions();
    const [slideIndex, setSlideIndex] = React.useState(0);

    return (
        <Grid container id="phone">
            <Grid item xs={12}>
                <Carousel
                    className="carousel"
                    slidesToShow={width > 600 ? 5 : 1.2}
                    cellSpacing={-10}
                    afterSlide={slideIndex => setSlideIndex(slideIndex)}
                    withoutControls={true}
                    renderBottomCenterControls={ null }
                    transitionMode={width > 600 ? "scroll" : "scroll3d"}
                >
                    <Item name="Home" image={phone1} index={slideIndex} order={0} setSlideIndex={setSlideIndex} />
                    <Item name="Cards" image={phone2} index={slideIndex} order={1} setSlideIndex={setSlideIndex} />
                    <Item name="Payments" image={phone3} index={slideIndex} order={2} setSlideIndex={setSlideIndex} />
                    <Item name="Contacts" image={phone4} index={slideIndex} order={3} setSlideIndex={setSlideIndex} />
                    <Item name="Details" image={phone5} index={slideIndex} order={4} setSlideIndex={setSlideIndex} />
                </Carousel>
            </Grid>
        </Grid>
    );

}

interface ItemProps {
    name: string,
    image: string,
    index: number,
    order: number,
    setSlideIndex: (value: number) => void;
}

const Item = ({ name, image, index, order, setSlideIndex }: ItemProps) => {
    return (
        <div className={`itemContent ${index === order ? 'selected' : ''}`} onClick={() => setSlideIndex(order)}>
            <Grid container>
                <Grid item xs={12}>
                    <img src={image} className={`itemImage ${index === order ? 'selected' : ''}`} />
                    {index === order ? (
                        <Typography className="name">{name}</Typography>
                    ) : (null)}
                </Grid>
                    <Grid item xs={12} className="empty"></Grid>
                </Grid>
        </div>
    );
}

export default Phone;