import { Typography } from "@mui/material";
import './SubTitleComing.css'

interface Props {
    content: string;
}

const SubTitleComing = ({content}: Props) => {

    return (
        <Typography component='h6' id='subTitleComing' className="sectionSubTitleComing">{content}</Typography>
    );
}

export default SubTitleComing;