import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import ibanCards from "../../assets/images/iban_cards.png";
import PrimaryButton from '../shared/Buttons/PrimaryButton';
import InputDialog from '../shared/Inputs/input.dialog';
import './IBAN.css';

const IBAN = () => {
    
    const [state, setState] = React.useState({
        email: ''
    })

    const [saved, setSaved] = React.useState(false);

    const handleChange = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        setState({
            ...state,
            [e.target.name]: e.target.value.trim(),
        });
    }

    return (
        <>
            <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
                <Grid container id="IBAN">
                    <Grid item sm={12} md={6}>
                        <Typography className='title'>IBANs</Typography>
                        <Typography className='text'>All accounts are automatically assigned a main IBAN. Use these to receive or make transfers all across Europe.</Typography>
                        <Typography className='phone'>Try now, no strings attached</Typography>
                    <InputDialog name="email" title="Enter your Email" value={state.email} onChange={handleChange} />
                    <PrimaryButton className="button"><span>Join Whitelist</span></PrimaryButton>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <img src={ibanCards} alt="" className="image" />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}>
                <Grid container id="IBAN">
                    <Grid item xs={12}>
                        <Typography className='title'>IBANs</Typography>
                        <Typography className='text'>All accounts are automatically assigned a main IBAN. Use these to receive or make transfers all across Europe.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <img src={ibanCards} alt="" className="image" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className='phone'>Try now, no strings attached</Typography>
                    <InputDialog name="email" title="Enter your Email" value={state.email} onChange={handleChange} />
                    <PrimaryButton className="button"><span>Join Whitelist</span></PrimaryButton>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default IBAN;