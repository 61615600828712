import { Typography } from "@mui/material";
import { useEffect } from "react";
import './Contacts.css';

const Contacts = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id="terms">
            <div className="termsBox">
                <Typography className="title">Contacts</Typography>
                
Address: Exchange Tower London E14 9SR.<br/>
Phone from the UK: 0800 023 4567.<br/>
Phone from outside the UK: +44 20 7964 0500.<br/>
E-mail: business@xwardpay.io 
<br/><br/>

            </div>
        </div>
    );
};

export default Contacts;
